<app-phone-catcher-header></app-phone-catcher-header>
<div class="phone-catcher-container">
    <span class="description">Entre com seu número de telefone para testar nosso robô</span>
    <form [formGroup]="form">
        <app-phone-catcher-input type="text" class="phone-number-input" placeholder="Seu número de telefone"
            mask="(00) 00000-0000" [control]="$any(form.controls['phone'])">
        </app-phone-catcher-input>
    </form>
    <app-button className="btn btn-accent" type="submit" label="Continuar" (clickEvent)="catchPhoneNumber()">
    </app-button>
</div>