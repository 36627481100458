import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './layouts/mobile/chat/chat.component';
import { MobileComponent } from './layouts/mobile/mobile.component';
import { PhoneCatcherComponent } from './layouts/mobile/phone-catcher/phone-catcher.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'mobile',
    pathMatch: 'full',
  },
  {
    path: 'mobile',
    component: MobileComponent,
    children: [
      {
        path: '',
        redirectTo: 'phone-catcher',
        pathMatch: 'full',
      },
      {
        path: 'phone-catcher',
        component: PhoneCatcherComponent,
      },
      {
        path: 'chat',
        component: ChatComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
