// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ANOTA_AI_BASE_IA_URL: 'https://staging-intelligence.anota-ai.com',
  ANOTA_AI_BASE_SOCKET_URL: 'https://staging-realtime.herokuapp.com',
  ANOTA_AI_EXAMPLE_PAGE_TOKEN:
    'eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI1ZGIxZjExMGM2Y2ZhNjZiODMxOGY2NDYiLCJpZHBhZ2UiOiI1Y2JlMDU1MmIyNjZlNzAwMjRlMzVlNmQifQ.882FpRr7qS_5JU7mO7aqQx4ibpi-F5W13YUTa4HnzzY',
  ANOTA_AI_EXAMPLE_PAGE_ID: '5cbe0552b266e70024e35e6d',
  SOCKET_URL_BASE: 'https://staging-realtime.herokuapp.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
