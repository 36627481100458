import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-phone-catcher-input',
  templateUrl: './phone-catcher-input.component.html',
  styleUrls: ['./phone-catcher-input.component.scss'],
})
export class PhoneCatcherInputComponent implements OnInit {
  @Input()
  public label: string = '';
  @Input()
  public type: 'text' | 'tel' | 'number' | 'email' | 'password' = 'text';
  @Input()
  public placeholder = '';
  @Input()
  public control: FormControl = new FormControl();
  @Input()
  public mask = '';
  @Input()
  public disabled = false;

  constructor() {}

  ngOnInit(): void {}
}
