<div class="phone-catcher-input-container">
    <div class="country-id-container">
        <span>+55</span>
    </div>
    <div class="phone-number-container">
        <input [type]="type" [placeholder]="placeholder" [formControl]="control" [mask]="mask" [disabled]="disabled" />
    </div>
</div>
<span class="error-message" *ngIf="control?.touched && control?.errors?.required">Este campo é obrigatório.</span>
<span class="error-message" *ngIf="
      control?.touched &&
      !control?.errors?.required &&
      control?.errors?.minlength
    ">Há números faltando.</span>