import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  constructor() {}

  transform(text: any): any {
    var urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const urlReplace = text.replace(urlRegex, function (url: any) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>';
    });

    const boldRegex = /(\*)(.*?)(\*)/gi;
    const boldRegexReplace = urlReplace.replace(
      boldRegex,
      function (bold: any) {
        return '<b>' + bold.substring(1, bold.length - 1) + '</b>';
      }
    );

    return boldRegexReplace;
  }
}
