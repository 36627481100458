import { Component, Input, OnInit } from '@angular/core';

export interface IBubbleChat {
  message: string;
  type: 'SENT' | 'RECEIVED';
  time: string;
}

@Component({
  selector: 'app-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
})
export class ChatBubbleComponent implements OnInit {
  @Input() chat: IBubbleChat = {
    message: '...',
    type: 'SENT',
    time: '',
  };

  constructor() {}

  ngOnInit(): void {}
}
