import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MobileComponent } from './layouts/mobile/mobile.component';
import { ChatComponent } from './layouts/mobile/chat/chat.component';
import { ChatBubbleComponent } from './layouts/mobile/chat/components/chat-bubble/chat-bubble.component';
import { WriteChatPanelComponent } from './layouts/mobile/chat/components/write-chat-panel/write-chat-panel.component';
import { TopBarComponent } from './layouts/mobile/components/top-bar/top-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LinkifyPipe } from './shared/pipes/linkfy.pipe';
import { environment } from 'src/environments/environment';
import { PhoneCatcherComponent } from './layouts/mobile/phone-catcher/phone-catcher.component';
import { ChatHeaderComponent } from './layouts/mobile/chat/components/chat-header/chat-header.component';
import { PhoneCatcherHeaderComponent } from './layouts/mobile/phone-catcher/components/phone-catcher-header/phone-catcher-header.component';
import { PhoneCatcherInputComponent } from './layouts/mobile/phone-catcher/components/phone-catcher-input/phone-catcher-input.component';
import { ButtonComponent } from './shared/components/button/button.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    AppComponent,
    MobileComponent,
    ChatComponent,
    ChatBubbleComponent,
    WriteChatPanelComponent,
    TopBarComponent,
    LinkifyPipe,
    PhoneCatcherComponent,
    ChatHeaderComponent,
    PhoneCatcherHeaderComponent,
    PhoneCatcherInputComponent,
    ButtonComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
