import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-phone-catcher',
  templateUrl: './phone-catcher.component.html',
  styleUrls: ['./phone-catcher.component.scss'],
})
export class PhoneCatcherComponent implements OnInit {
  public form: FormGroup = new FormGroup({});

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.form = new FormGroup({
      phone: new FormControl(
        '',
        [Validators.required, Validators.minLength(11)],
        []
      ),
    });
  }

  catchPhoneNumber() {
    console.log('Catch phone number', this.form.value);
    const { phone } = this.form.value;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    const senderId = `55${phone.slice(0, 2) + phone.slice(3)}@c.us`;
    console.log('senderId', senderId);
    sessionStorage.setItem('@anotaai/senderId', senderId);
    sessionStorage.setItem('@anotaai/phoneNumber', phone);
    this.router.navigate(['/mobile/chat']);
  }
}
