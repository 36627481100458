import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IMessageDTO } from './DTOs/messageDTO.interface';

@Injectable({
  providedIn: 'root',
})
export class AnotaaiService {
  constructor(private http: HttpClient) {}

  sendMessage(message: string, senderId: string) {
    console.log(environment.ANOTA_AI_EXAMPLE_PAGE_TOKEN);
    const url = `${environment.ANOTA_AI_BASE_IA_URL}/webhookwhatsapp`;
    const messageDTO: IMessageDTO = {
      sender: {
        idwhats: senderId,
        picture: '',
      },
      type: 'chat',
      message,
    };
    return this.http.post(url, messageDTO, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: environment.ANOTA_AI_EXAMPLE_PAGE_TOKEN,
      },
    });
  }
}
