import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-write-chat-panel',
  templateUrl: './write-chat-panel.component.html',
  styleUrls: ['./write-chat-panel.component.scss'],
})
export class WriteChatPanelComponent implements OnInit {
  @Output()
  public sendMessageEvent = new EventEmitter();

  public form: FormGroup = new FormGroup({});

  constructor() {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.form = new FormGroup({
      message: new FormControl('', [Validators.required]),
    });
  }

  public submit() {
    if (this.form.get('message')?.invalid) {
      return;
    }
    this.sendMessageEvent.emit(this.form.value);
    this.form.reset();
  }
}
