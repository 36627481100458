<div class="header-container">
    <div class="avatar-container">
        <svg width="36" height="36" viewBox="0 0 36 36">
            <defs>
                <style>
                    .b {
                        fill: #1a365d;
                    }

                    .c {
                        fill: #f7f8f9;
                    }
                </style>
            </defs>
            <circle class="b" cx="18" cy="18" r="18" />
            <g>
                <path class="c"
                    d="M18,2.09C9.23,2.09,2.09,9.23,2.09,18s7.14,15.91,15.91,15.91,15.91-7.14,15.91-15.91S26.77,2.09,18,2.09Zm0,30.89c-8.26,0-14.98-6.72-14.98-14.98S9.74,3.02,18,3.02s14.98,6.72,14.98,14.98-6.72,14.98-14.98,14.98Z" />
                <path class="c"
                    d="M18,7.69c-5.69,0-10.31,4.63-10.31,10.31s4.63,10.31,10.31,10.31,10.31-4.63,10.31-10.31-4.63-10.31-10.31-10.31Zm0,19.7c-5.18,0-9.39-4.21-9.39-9.39s4.21-9.39,9.39-9.39,9.39,4.21,9.39,9.39-4.21,9.39-9.39,9.39Z" />
                <polygon class="c"
                    points="12.01 9.25 10.63 6.82 12.41 9.02 12.92 8.73 11.89 6.06 13.29 8.52 13.8 8.23 11.85 4.8 11.1 5.23 12.12 7.87 10.34 5.66 9.59 6.09 11.54 9.51 12.01 9.25" />
                <polygon class="c"
                    points="17.25 7.46 17.16 6.9 16.1 7.06 15.93 5.92 16.77 5.79 16.69 5.24 15.85 5.36 15.69 4.28 16.74 4.12 16.66 3.56 14.99 3.81 15.58 7.71 17.25 7.46" />
                <polygon class="c"
                    points="20.12 4.77 20.35 7.72 20.98 7.83 21.71 3.97 21.17 3.86 20.73 6.18 20.54 3.75 19.78 3.6 19.05 7.47 19.6 7.57 20.12 4.77" />
                <path class="c"
                    d="M23.46,9c.52,.31,.98,.16,1.3-.38l1.52-2.58-.5-.3-1.54,2.62c-.14,.24-.3,.27-.47,.17s-.22-.25-.08-.49l1.54-2.62-.53-.31-1.52,2.58c-.32,.54-.23,1.01,.29,1.32Z" />
                <polygon class="c"
                    points="11.71 29.86 12.59 27.16 11.83 26.77 10.02 30.26 10.5 30.51 11.78 28.04 10.91 30.72 11.42 30.99 13.1 28.68 11.8 31.19 12.32 31.46 14.14 27.97 13.37 27.57 11.71 29.86" />
                <polygon class="c"
                    points="15.16 32.35 16.83 32.63 16.92 32.07 15.86 31.9 16.05 30.76 16.89 30.9 16.98 30.35 16.14 30.21 16.32 29.12 17.38 29.3 17.47 28.74 15.81 28.47 15.16 32.35" />
                <polygon class="c"
                    points="20.41 28.39 20.94 30.69 19.79 28.53 19.04 28.7 19.91 32.54 20.45 32.42 19.82 29.64 21.2 32.25 21.82 32.11 20.95 28.27 20.41 28.39" />
                <path class="c"
                    d="M24.17,26.71l-.51,.29,1.5,2.64c.14,.24,.08,.39-.09,.49-.17,.1-.33,.07-.47-.17l-1.5-2.64-.54,.31,1.48,2.61c.31,.55,.76,.71,1.29,.41s.62-.77,.31-1.32l-1.48-2.61Z" />
                <path class="c"
                    d="M23.26,17.26c-1.69-.08-2.15-.07-2.28-.07-.27,0-1.5,.09-1.85,.45-.06,.06-.39,.29-.5,.33-.65,.29-1.29,.15-1.29,.15,0,0-2.1-.34-2.82-.48-.08-.02-.12-.07-.11-.1,.02-.05,.08-.05,.11-.04,.78,.04,3.05,.18,3.12,.17,.08,0,.19,.04,.23-.1,.04-.15-.18-.15-.18-.15,0,0-2.38-.1-3.17-.2-.08-.01-.11-.05-.11-.08,0-.07,.11-.07,.11-.07,0,0,2.96,.01,3.14,.01,.22,0,.22-.1,.22-.1,0-.02,0-.03,0-.04,0-.01,0-.03,0-.04,0,0,0-.1-.22-.1-.18,0-3.14,.01-3.14,.01,0,0-.11,0-.11-.07,0-.02,.02-.07,.11-.08,.79-.09,3.17-.2,3.17-.2,0,0,.22,0,.18-.15-.04-.14-.15-.1-.23-.1-.07,0-2.33,.14-3.12,.17-.03,0-.1,0-.11-.04,0-.02,.02-.08,.11-.09,.71-.14,2.82-.48,2.82-.48,0,0,.64-.14,1.29,.15,.1,.05,.44,.27,.5,.33,.35,.36,1.58,.45,1.85,.45,.12,0,.55,0,1.79-.06-1.07-1.17-2.57-1.94-4.25-2.06,.04-.07,.06-.14,.06-.22,0-.26-.21-.46-.46-.46s-.46,.21-.46,.46c0,.08,.02,.15,.05,.22-2.8,.16-5.12,2.14-5.79,4.78,.15,.02,.26,.04,.33,.04,1.67,.08,2.43,.1,2.77,.1,0,0,.53-.39,.77-.48,2.89-1.01,5.48,.04,5.85,.62,.37,.55,0,.69,0,.69,0,0-7.21-.33-9.39-.24-.09,0-.26,.03-.48,.07-.02,.13-.03,.26-.03,.39h-.46c-.43,0-.78,.35-.78,.78s.35,.78,.78,.78h13.69c.43,0,.78-.35,.78-.78s-.35-.78-.78-.78h-.54c-.07-1.22-.48-2.35-1.15-3.29Z" />
                <path class="c"
                    d="M5.73,18.09s.11-.07,.11-.07l1-.95s.05-.09-.11-.11c-.16-.02-.58,.14-1.15,.97l-.1,.1c0-.06,0-.1,0-.11,0-.12-.03-.3,.14-.4,.01,0,.38-.33,.08-.89-.3-.57-.55,.1-.57,.17-.02,.07-.18,.46,.13,.72,0,0,.12,.05,.12,.38,0,.04,0,.1,0,.17-.08-.07-.14-.12-.14-.13-.04-.04-.1-.13-.09-.24,0-.02-.02-.11-.03-.13-.06-.15-.3-.35-.3-.35l-.5-.39s-.02,.02-.02,.02c0,0,.49,.47,.5,.49,.01,.01,.04,.03,.02,.05-.02,.02-.05,0-.05,0l-.53-.48s-.02,.03-.02,.03c0,0,.46,.47,.49,.5,.04,.03,.02,.05,.02,.05-.03,.03-.06,0-.06,0l-.51-.49s-.02,.02-.02,.02c0,0,.48,.52,.49,.53,0,0,.04,.02,.01,.04-.02,.02-.05,0-.05,0l-.51-.5s-.02,.02-.02,.02l.51,.62c.12,.17,.31,.21,.31,.21,.18,0,.29,.09,.29,.09,.04,.02,.1,.08,.18,.16-.38,.38-1.06,1.06-1.25,1.2,0,0,.05,.22,.25,.24,0,0,.58-.7,1.01-1.15-.03,.5-.08,1.11-.08,1.11,0,0,.14,.11,.3,0,0,0-.04-.73-.07-1.22,.45,.47,1.11,1.26,1.11,1.26,0,0,.19-.03,.24-.23,0,0-.81-.71-1.28-1.14,.07-.07,.13-.12,.17-.15Z" />
                <path class="c"
                    d="M30.91,18.23c.07-.07,.13-.12,.17-.15,.02-.01,.11-.07,.11-.07l1-.95s.05-.09-.11-.11c-.16-.02-.58,.14-1.15,.97,0,0-.04,.04-.1,.1,0-.06,0-.1,0-.11,0-.12-.03-.3,.14-.4,.01,0,.38-.33,.08-.89-.3-.57-.55,.1-.57,.17-.02,.07-.18,.46,.13,.72,0,0,.12,.05,.12,.38,0,.04,0,.1,0,.17-.08-.07-.14-.12-.14-.13-.04-.04-.1-.13-.09-.24,0-.02-.02-.11-.03-.13-.06-.15-.3-.35-.3-.35l-.5-.39s-.02,.02-.02,.02c0,0,.49,.47,.5,.49,.01,.01,.04,.03,.02,.05-.02,.02-.05,0-.05,0l-.53-.48s-.02,.03-.02,.03c0,0,.46,.47,.49,.5,.04,.03,.02,.05,.02,.05-.03,.03-.06,0-.06,0l-.51-.49s-.02,.02-.02,.02c0,0,.48,.52,.49,.53,0,0,.04,.02,.01,.04-.02,.02-.05,0-.05,0l-.51-.5s-.02,.02-.02,.02l.51,.62c.12,.17,.31,.21,.31,.21,.18,0,.29,.09,.29,.09,.04,.02,.1,.08,.18,.16-.38,.38-1.06,1.06-1.25,1.2,0,0,.05,.22,.25,.24,0,0,.58-.7,1.01-1.15-.03,.5-.08,1.11-.08,1.11,0,0,.14,.11,.3,0,0,0-.04-.73-.07-1.22,.45,.47,1.11,1.26,1.11,1.26,0,0,.19-.03,.24-.23,0,0-.81-.71-1.28-1.14Z" />
            </g>
        </svg>
        <div class="contact-data">
            <span class="name">Fast Meal Delivery</span>
            <span class="status">{{ isWriting ? 'Digitando...' :'Online'}}</span>
        </div>
    </div>
    <div class="actions">
        <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M17 4.5C17 2.29086 15.2091 0.5 13 0.5H4C1.79086 0.5 0 2.29086 0 4.5V12C0 14.2091 1.79086 16 4 16H13C15.2091 16 17 14.2091 17 12V4.5ZM4 1.7H13C14.5464 1.7 15.8 2.9536 15.8 4.5V12C15.8 13.5464 14.5464 14.8 13 14.8H4C2.4536 14.8 1.2 13.5464 1.2 12V4.5C1.2 2.9536 2.4536 1.7 4 1.7ZM24.2926 2.42115C24.4271 2.59596 24.5 2.81032 24.5 3.03086V13.2104C24.5 13.7627 24.0523 14.2104 23.5 14.2104C23.3149 14.2104 23.1335 14.1591 22.9759 14.0621L18.9518 11.5857C18.3603 11.2217 18 10.5769 18 9.88241V6.9848C18 6.36386 18.2884 5.77815 18.7806 5.39956L22.8903 2.23824C23.328 1.9015 23.9559 1.9834 24.2926 2.42115ZM19.5122 6.3507L23.3 3.43704V12.8525L19.5807 10.5637C19.3441 10.4181 19.2 10.1602 19.2 9.88241V6.9848C19.2 6.73643 19.3154 6.50214 19.5122 6.3507Z"
                fill="#54656f" />
        </svg>
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M8.67955 4.9567L6.87343 1.90414C6.06909 0.544685 4.31498 0.0946839 2.95553 0.899035C2.71245 1.04286 2.49201 1.2219 2.30139 1.43033C0.944762 2.9137 0.337482 4.32605 0.53717 5.66529C0.946397 8.40983 2.87595 11.5447 6.30409 15.1037L6.60181 15.3978L6.89633 15.6959L6.90214 15.6916C10.4553 19.124 13.5902 21.0536 16.3347 21.4628C17.674 21.6625 19.0863 21.0552 20.5697 19.6986C20.7781 19.508 20.9571 19.2876 21.101 19.0445C21.9053 17.685 21.4553 15.9309 20.0959 15.1266L17.0433 13.3205L16.8915 13.2378C16.1728 12.8796 15.3176 12.9036 14.6167 13.3109L13.6655 13.8635L13.555 13.9209C13.0684 14.1443 12.488 14.0448 12.1032 13.6601L8.33993 9.89675L8.25623 9.80463C7.91561 9.3915 7.86311 8.80493 8.13646 8.33449L8.68913 7.38334C9.12549 6.63237 9.12182 5.70419 8.67955 4.9567ZM19.7598 18.8131C18.5048 19.9609 17.4288 20.4127 16.5117 20.2759C14.1546 19.9245 11.3397 18.2373 8.08615 15.1632L7.52591 14.6257L7.14745 14.25C3.88274 10.8601 2.0878 7.92787 1.72405 5.48832C1.5873 4.57117 2.03915 3.49517 3.1869 2.24018C3.29755 2.1192 3.42549 2.01528 3.56659 1.9318C4.31182 1.49087 5.26138 1.69935 5.75788 2.3886L5.84067 2.5152L7.64678 5.56776C7.8678 5.94131 7.86964 6.40517 7.65157 6.78046L7.0989 7.73162C6.57438 8.63431 6.66918 9.7661 7.33036 10.568L7.45178 10.7037L11.2547 14.5086C11.993 15.2468 13.1113 15.4451 14.0558 15.0114L14.218 14.9288L15.2195 14.3484C15.5686 14.1456 15.9961 14.1323 16.3562 14.3118L16.4697 14.3745L19.4848 16.1593C20.2739 16.6262 20.5351 17.6443 20.0682 18.4334C19.9847 18.5745 19.8808 18.7025 19.7598 18.8131Z"
                fill="#54656f" />
        </svg>
    </div>
</div>