import { Component, OnInit } from '@angular/core';
import { AnotaaiService } from 'src/app/shared/services/anotaai/anotaai.service';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
export interface IChat {
  message: string;
  type: 'SENT' | 'RECEIVED';
  time: string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {
  public chats: Array<IChat> = [];
  public senderId: string = '';
  public senderPhoneNumber: string = '';
  private socket: any;
  public isWriting: boolean = false;

  constructor(private anotaaiService: AnotaaiService, private router: Router) {}

  ngOnInit(): void {
    this.initRobotSession();
    this.listenOrderNotifications();
  }

  public async initRobotSession() {
    const sessionStorageSenderId = sessionStorage.getItem('@anotaai/senderId');
    const sessionStoragePhoneNumber = sessionStorage.getItem(
      '@anotaai/phoneNumber'
    );

    if (!sessionStorageSenderId || !sessionStoragePhoneNumber) {
      this.router.navigate(['/mobile/phone-catcher']);
      return;
    }

    this.senderId = sessionStorageSenderId;
    this.senderPhoneNumber = sessionStoragePhoneNumber;

    const welcomeMessages = [
      this.unshiftNewMessage('Olá, tudo bem?'),
      this.unshiftNewMessage(
        'Eu sou o robô da Anota AI e este é nosso estabelecimento de teste.'
      ),
      this.unshiftNewMessage(
        'Você pode me enviar suas principais dúvidas, como:<br> - Vocês estão abertos?<br> - Hoje tem promoção?<br> - Qual a taxa de entrega?'
      ),
    ];
    await Promise.race(welcomeMessages);
  }

  public unshiftNewMessage(message: string) {
    return new Promise((resolve) => {
      this.isWriting = true;
      const wait = setTimeout(() => {
        clearTimeout(wait);
        this.isWriting = false;
        this.chats.unshift({
          message: message,
          time: this.getHour(),
          type: 'RECEIVED',
        });
        resolve;
      }, ((message.length > 200 ? 200 : message.length) / 2) * 50);
    });
  }

  public getHour() {
    let date = new Date();
    let hour = date.getHours();
    let minutes = date.getMinutes();
    return `${hour < 10 ? '0' + hour : hour}:${
      minutes < 10 ? '0' + minutes : minutes
    }`;
  }

  public sendMessage(e: any) {
    this.chats.unshift({
      message: e.message,
      time: this.getHour(),
      type: 'SENT',
    });
    this.anotaaiService
      .sendMessage(e.message, this.senderId)
      .subscribe(async (response: any) => {
        const messages = response.arrayMessage;
        const messagesToUnshift = messages.map((message: any) => {
          return this.unshiftNewMessage(message.content);
        });
        await Promise.race(messagesToUnshift);
      });
  }

  public listenOrderNotifications() {
    this.socket = io.connect(environment.SOCKET_URL_BASE, {
      transports: ['websocket'],
      query: {
        token: environment.ANOTA_AI_EXAMPLE_PAGE_TOKEN,
        whatsapp: true,
      },
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 30000,
      reconnectionAttempts: 99999,
    });

    this.socket.on('connect', () => {
      this.socket.emit('subscribe', environment.ANOTA_AI_EXAMPLE_PAGE_ID, true);
      console.log('connected');
    });

    this.socket.on('order', async (data: any) => {
      console.log('order', data);
      if (data.client_phone == this.senderPhoneNumber) {
        const finishingMessages = [
          this.unshiftNewMessage(
            'Seu pedido foi realizado com sucesso. Vou te atualizando sobre o seu pedido por aqui 😜.'
          ),
          this.unshiftNewMessage(
            'Já estamos cortando os ingredientes do seu pedido 😋. Avisaremos quando ele estiver pronto. Enquanto isso, que tal uma partida de basquete? 🏀 https://basquetebol.anota.ai'
          ),
        ];
        await Promise.race(finishingMessages);
      }
    });

    // this.socket.on('orderInAnalyze', (data: any) => {
    //   console.log('NOVO PEDIDO - orderInAnalyze', data);
    //   if (data.client_phone == this.senderPhoneNumber) {
    //     console.log('NOVO PEDIDO desse magrão');
    //   }
    // });

    // this.socket.on('updateStatus', async (data: any) => {
    //   console.log('NOVO PEDIDO - updateStatus', data);
    //   if (data.client_phone == this.senderPhoneNumber) {
    //     console.log('NOVO PEDIDO desse magrão');
    //   }
    // });

    this.socket.on('disconnect', function () {
      console.log('DESCONECTADO');
    });

    this.socket.on('error', (e: any) => {
      console.log('socket - error', e);
    });
  }
}
