<div class="chat-bubble-container" [ngClass]="chat.type == 'SENT' ? 'sent2' : 'received2'">
    <div class="chat-bubble" [ngClass]="chat.type == 'SENT' ? 'sent' : 'received'">
        <span [innerHtml]="chat.message | linkify"></span>
        <br>
        <div class="send-hour-container">
            <span class="send-hour">{{chat.time}}</span> <svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M13.501 0.108955L14 0.59573L8.60042 8L6.41166 5.83835L7.33857 4.56645L8.60042 5.30239L13.501 0.108955ZM9.11925 0L9.61822 0.486775L4.21865 7.89105L0.5 4.23358L1.33033 3.42354L4.21865 5.19343L9.11925 0Z"
                    fill="#3497F9" />
            </svg>
        </div>
    </div>
</div>