<div class="top-bar-container">
    <span class="hour">{{getHour()}}</span>
    <div class="end-container">
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1.66666 7.33329H2.66666C3.21894 7.33329 3.66666 7.78101 3.66666 8.33329V10.3333C3.66666 10.8856 3.21894 11.3333 2.66666 11.3333H1.66666C1.11437 11.3333 0.666656 10.8856 0.666656 10.3333V8.33329C0.666656 7.78101 1.11437 7.33329 1.66666 7.33329ZM6.33332 5.33329H7.33332C7.88561 5.33329 8.33332 5.78101 8.33332 6.33329V10.3333C8.33332 10.8856 7.88561 11.3333 7.33332 11.3333H6.33332C5.78104 11.3333 5.33332 10.8856 5.33332 10.3333V6.33329C5.33332 5.78101 5.78104 5.33329 6.33332 5.33329ZM11 2.99996H12C12.5523 2.99996 13 3.44767 13 3.99996V10.3333C13 10.8856 12.5523 11.3333 12 11.3333H11C10.4477 11.3333 9.99999 10.8856 9.99999 10.3333V3.99996C9.99999 3.44767 10.4477 2.99996 11 2.99996ZM15.6667 0.666626H16.6667C17.2189 0.666626 17.6667 1.11434 17.6667 1.66663V10.3333C17.6667 10.8856 17.2189 11.3333 16.6667 11.3333H15.6667C15.1144 11.3333 14.6667 10.8856 14.6667 10.3333V1.66663C14.6667 1.11434 15.1144 0.666626 15.6667 0.666626Z"
                fill="#060606" />
        </svg>
        <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M8.33033 2.60802C10.5462 2.60811 12.6774 3.45955 14.2833 4.98635C14.4043 5.10422 14.5976 5.10274 14.7167 4.98302L15.8727 3.81635C15.933 3.75563 15.9666 3.67338 15.9661 3.5878C15.9656 3.50221 15.931 3.42036 15.87 3.36035C11.6549 -0.679198 5.0051 -0.679198 0.789998 3.36035C0.728939 3.42032 0.694296 3.50215 0.693733 3.58773C0.69317 3.67331 0.726734 3.75558 0.786998 3.81635L1.94333 4.98302C2.06236 5.10292 2.25581 5.10441 2.37666 4.98635C3.98282 3.45945 6.11422 2.60801 8.33033 2.60802ZM8.33033 6.40368C9.54782 6.40361 10.7219 6.85614 11.6243 7.67335C11.7464 7.78933 11.9387 7.78682 12.0577 7.66768L13.2123 6.50102C13.2731 6.43982 13.3069 6.3568 13.306 6.27054C13.3051 6.18427 13.2697 6.10196 13.2077 6.04202C10.4595 3.48563 6.20353 3.48563 3.45533 6.04202C3.39325 6.10196 3.35784 6.18432 3.35702 6.27061C3.35621 6.3569 3.39006 6.43991 3.451 6.50102L4.60533 7.66768C4.72432 7.78682 4.9166 7.78933 5.03866 7.67335C5.94054 6.85668 7.11365 6.40419 8.33033 6.40368ZM10.5493 9.18802C10.6111 9.12741 10.6451 9.04401 10.6433 8.9575C10.6416 8.87099 10.6042 8.78905 10.54 8.73102C9.26443 7.65213 7.39624 7.65213 6.12067 8.73102C6.05644 8.789 6.019 8.87092 6.01717 8.95743C6.01534 9.04394 6.04929 9.12737 6.111 9.18802L8.10866 11.2037C8.16722 11.2629 8.24704 11.2963 8.33033 11.2963C8.41362 11.2963 8.49345 11.2629 8.552 11.2037L10.5493 9.18802Z"
                fill="#060606" />
        </svg>
        <svg width="25" height="11" viewBox="0 0 25 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.5 0C1.11929 0 0 1.11929 0 2.5V8C0 9.38071 1.11929 10.5 2.5 10.5H18.4687H19.5C20.8807 10.5 22 9.38071 22 8V2.5C22 1.11929 20.8807 0 19.5 0H2.5ZM3 1C1.89543 1 1 1.89543 1 3V7.5C1 8.60457 1.89543 9.5 3 9.5H17.4687H19C20.1046 9.5 21 8.60457 21 7.5V3C21 1.89543 20.1046 1 19 1H3Z"
                fill="#ABABAB" />
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M23 7.37398C23.8626 7.15196 24.5 6.36891 24.5 5.43699C24.5 4.50507 23.8626 3.72202 23 3.5V7.37398Z"
                fill="#ABABAB" />
            <rect x="2" y="2" width="18" height="6.5" rx="1" fill="#060606" />
        </svg>
    </div>
</div>