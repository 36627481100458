<form [formGroup]="form" class="write-chat-container">
    <input formControlName="message" placeholder="Escreva uma mensagem" (keyup.enter)="submit()">
    <a (click)="submit()">
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="13.5" cy="13.5" r="13.5" fill="#007AFF" />
            <path
                d="M23.5925 12.9421L7.11209 6.06602C6.98292 6.01211 6.83783 5.99052 6.6943 6.00382C6.55076 6.01713 6.41489 6.06478 6.30305 6.14103C6.19625 6.21572 6.11653 6.3138 6.07286 6.42423C6.02919 6.53466 6.0233 6.65306 6.05585 6.76613L8.04098 12.8733H16.5134V14.1235H8.04098L6.02588 20.212C5.99534 20.3064 5.99177 20.4055 6.01547 20.5013C6.03917 20.5971 6.08948 20.6869 6.16234 20.7635C6.2352 20.8401 6.32859 20.9014 6.435 20.9424C6.5414 20.9834 6.65785 21.003 6.77499 20.9996C6.89226 20.999 7.00772 20.9755 7.11209 20.9309L23.5925 14.0548C23.7152 14.0023 23.8181 13.9226 23.89 13.8245C23.962 13.7263 24 13.6135 24 13.4984C24 13.3834 23.962 13.2706 23.89 13.1724C23.8181 13.0743 23.7152 12.9946 23.5925 12.9421V12.9421Z"
                fill="#FEFEFF" />
        </svg>
    </a>
</form>